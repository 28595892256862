import { render, staticRenderFns } from "./Yetki.vue?vue&type=template&id=e57e3246&scoped=true&"
import script from "./Yetki.vue?vue&type=script&lang=js&"
export * from "./Yetki.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e57e3246",
  null
  
)

export default component.exports